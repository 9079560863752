<template>
  <div v-loading.fullscreen.lock="loading">
    <reimbursement-category-search-bar-component
      add_auth="新增报销类目"
      @search="get_reimbursement_category_index"
      @addTeam="addTeam"
    ></reimbursement-category-search-bar-component>
    <common-table-component
      details_auth="报销类目详情"
      :col_arr="fcol_arr"
      :table_data="ftable_data"
      @details_row="details_reimbursement_category"
    ></common-table-component>
    <common-page-component
      :current_page="cond.page"
      :page_size="cond.limit"
      :total="ftotal"
      @search="get_reimbursement_category_index"
    ></common-page-component>
    <reimbursement-category-edit-component
      edit_auth="修改报销类目"
      del_auth="删除报销类目"
      :id="fid"
      :show_type="edit_type"
      :dialogFormVisible="fdialogFormVisible"
      :reimbursement_category_details_data="freimbursement_category_details_data"
      @exitReimbursementCategoryDialog="dialogExit"
      @search="get_reimbursement_category_index"
      @show_edit="show_edit"
      @details_row="details_reimbursement_category"
    ></reimbursement-category-edit-component>
  </div>
</template>

<script>
import {
  reimbursement_category_index_request,
  reimbursement_category_details_request,
} from "@/network/finance/ReimbursementCategory.js";

import ReimbursementCategorySearchBarComponent from "@/components/finance/ReimbursementCategory/ReimbursementCategorySearchBarComponent";
import CommonTableComponent from "@/components/common/CommonTableComponent";
import CommonPageComponent from "@/components/common/CommonPageComponent";
import ReimbursementCategoryEditComponent from "@/components/finance/ReimbursementCategory/ReimbursementCategoryEditComponent";

export default {
  name: "ReimbursementCategoryView",
  data() {
    return {
      fid: 0,
      edit_type: 0,
      fdialogFormVisible: false,
      loading: false,
      ftotal: 0,
      cond: {
        name: "",
        page: 1,
        limit: 10,
      },
      ftable_data: [],
      fcol_arr: [
        {
          prop: "name",
          label: "类目名称",
          minWidth: "200px",
        },
        {
          prop: "create_staff_name",
          label: "创建人",
          minWidth: "80px",
        },
        {
          prop: "create_time",
          label: "创建时间",
          minWidth: "200px",
        }, 
        
         
      ],
      freimbursement_category_details_data: {},
    };
  },
  computed: {},
  methods: {
    // 添加信息（弹框）
    addTeam() {
      this.edit_type = 1;
      this.fdialogFormVisible = true;
    },
    // 修改（弹框）
    show_edit() {
      this.edit_type = 2;
    },
    get_reimbursement_category_index(param = {}) {
      this.loading = true;
      this.cond.name = param.name ?? this.cond.name;
      this.cond.page = param.page ?? this.cond.page;
      if (param.limit) {
        this.cond.limit = param.limit;
        this.cond.page = 1;
      }
      reimbursement_category_index_request(this.cond)
        .then((s) => {
          this.loading = false;
          if (s.status === 0) {
            this.ftable_data = s.result.data;
            this.ftotal = s.result.total;
          } else {
            this.$message.error(s.msg);
          }
        })
        .catch((err) => {
          this.loading = false;
          this.$message.error(err);
        });
    },
    details_reimbursement_category(id) {
      this.fid = id;
      this.fdialogFormVisible = true;
      reimbursement_category_details_request(id)
        .then((s) => {
          if (s.status === 0) {
            this.freimbursement_category_details_data = s.result;
          } else {
            this.$message.error(s.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    dialogExit() {
      this.fdialogFormVisible = false;
      this.fid = 0;
      this.edit_type = 0;
    },
  },
  filters: {},
  props: {},
  created() {
    this.get_reimbursement_category_index();
  },
  destoryed() {},
  mounted() {},
  updated() {},
  components: {
    ReimbursementCategorySearchBarComponent,
    CommonTableComponent,
    CommonPageComponent,
    ReimbursementCategoryEditComponent,
  },
  watch: {},
};
</script>

<style lang="less"></style>
